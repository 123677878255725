<template>
  <div>
    <b-row>
      <b-col>
        <h2>Change Invoice Number</h2>
      </b-col>
    </b-row>
    <hr>

    <b-row v-if="!actionObj.ActionID">
      <b-col md="3">
        <b-form-group
          label="Lookup Case by Case Number"
          label-for="caseID"
        >
          <b-form-input
            id="caseID"
            v-model="CaseNumber"
            placeholder=""
            name="caseID"
          />
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 1.5rem;">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
          :disabled="!CaseNumber"
          @click="lookup()"
        >
          Lookup
        </b-button>
      </b-col>
    </b-row>
    <div v-if="caseObj.CaseID && !actionObj.ActionID">
      <b-row>
        <b-col>
          <span>
            <router-link :to="'/cases/' + caseObj.CaseID + '/detail'">
              <span>Case: {{ caseObj.FileNumber }}</span>
            </router-link>
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Action"
            label-for="action"
          >
            <v-select
              id="action"
              v-model="Action"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="actionOption"
              placeholder=""
              name="action"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="Action">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="changeInvoiceNumber()"
          >
            Change Invoice Number
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div v-if="actionObj.ActionID">
      <b-row>
        <b-col md="3">
          <span>
            <router-link :to="'/cases/' + caseObj.CaseID + '/detail'">
              <span>Case: {{ caseObj.FileNumber }}</span>
            </router-link>
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <span>
            <router-link :to="'/cases/edit-action/' + actionObj.ActionID">
              <span>Action: {{ actionObj.ActionFileNumber }}</span>
            </router-link>
          </span>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Invoice Number"
            label-for="invoiceNumber"
          >
            <b-form-input
              id="invoiceNumber"
              v-model="actionObj.BillingInvoiceNumber"
              placeholder=""
              name="invoiceNumber"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Invoice Amount $"
            label-for="invoiceAmount"
          >
            <MoneyInput
              v-model="actionObj.BillingInvoiceAmount"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Billed On Date"
            label-for="billedOnDate"
          >
            <b-form-input
              id="billedOnDate"
              v-model="actionObj.BilledOnDate"
              type="date"
              placeholder=""
              name="billedOnDate"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="billedOnDate()"
          >
            Save Changes
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import MoneyInput from "@core/components/moneyInput/MoneyInput";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    MoneyInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      caseObj: {},
      actionObj: {
        BillingInvoiceAmount: "0",
      },
      CaseNumber: "",
      Action: "",

      actionOption: [],
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    lookup() {
      apiService
          .get("case/get/byfilenumber/" + this.CaseNumber)
          .then((res) => {
            if (res.data.CaseID) {
              this.caseObj = res.data;
              this.loadActions();
            } else {
              this.showToast('danger', 'top-center', 4000, 'Case filenumber not found!');
            }
          })
    },
    loadActions() {
      apiService
          .get("actions/case/" + this.caseObj.CaseID)
          .then((res) => {
            this.actionOption = res.data.map(item => {
              return {title: item.ActionFileNumber, value: item.ActionFileNumber, id: item.ActionID}
            });
          });
    },
    changeInvoiceNumber() {
      apiService
          .get("action/" + this.Action.id + "/detail")
          .then(res => {
            const obj = res.data;
            const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
            let date;

            if (res.data.BilledOnDate) {
              date = new Date(res.data.BilledOnDate);
            } else {
              date = new Date();
            }
            obj.BilledOnDate = date.toLocaleDateString("en-US", options);

            if (!res.data.BillingInvoiceAmount) {
              obj.BillingInvoiceAmount = "0";
            } else {
              obj.BillingInvoiceAmount = String(res.data.BillingInvoiceAmount);
            }
            this.actionObj = obj;
          })
    },
    billedOnDate() {
      const postData = {
        BillingInvoiceNumber: this.actionObj.BillingInvoiceNumber,
        BilledOnDate: this.actionObj.BilledOnDate,
        BillingInvoiceAmount: this.actionObj.BillingInvoiceAmount,
      };
      apiService
          .post("action/" + this.Action.id + "/change-invoice-number", postData)
          .then(res => {
            if (res) {
              this.actionObj = {};
              this.Action = "";
              this.showToast('success', 'top-center', 4000, 'Invoice Number Changed!');
            }
          })
    },
  }
}
</script>

<style scoped>

</style>